import {Layout} from '../../components/layout';
import * as styles from './index.module.css';

const AnalyticsREEMEAPage = () => (
    <Layout title="Analytics (CEEMEA)" scope="analytics.reemea">
        <div className={styles.wrapper}>
            <iframe
                title="REEMEA_YT Quarterly Performace Dashboard_v4"
                width="100%"
                height="100%"
                src="https://app.powerbi.com/reportEmbed?reportId=54234421-edfe-4f56-b902-3b8946474d83&autoAuth=true&ctid=ae087f2d-8a82-46dd-a538-38bebd294479"
                style={{outline: '0', border: 'none'}}
            >
            </iframe>
        </div>
    </Layout>
);

export default AnalyticsREEMEAPage;
